import React from "react";
// import TitleSection from "./sections/TitleSection/TitleSection";
// import TryLuciteButton from "./sections/TryLuciteButton";
// import OverviewSection from "./sections/OverviewSection/OverviewSection";
// import StreamlineSection from "./sections/StreamlineSection/StreamlineSection";
// import LuciteIsSection from "./sections/LuciteIsSection/LuciteIsSection";
// import BanishSection from "./sections/BanishSection/BanishSection";
// import FooterSection from "./sections/FooterSection/FooterSection";
import TeaserSection from "./sections/TeaserSection/TeaserSection";

import "./App.css";

function App() {
  return (
    <div className="App">
      {/* <TitleSection />
      <OverviewSection />
      <StreamlineSection />
      <LuciteIsSection />
      <TryLuciteButton />
      <BanishSection />
      <FooterSection /> */}
      <TeaserSection />
    </div>
  );
}

export default App;
