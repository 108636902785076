import React from "react";
import "./TeaserSection.css";
import TryLuciteButton from "../TryLuciteButton"; // Import the TryLuciteButton component
import LoginButton from "../LoginButton"; // Import the LoginButton component

const TeaserSection = () => {
  return (
    <div className="teaser-section">
      <div className="header-text">
        <img
          src="lucite-logo-light.png"
          alt="Lucite Logo"
          className="lucite-logo-light"
        />
      </div>
      <div className="buttons-container">
        <TryLuciteButton />
        <LoginButton /> {/* Use the new LoginButton component */}
      </div>
      <div className="main-content-container">
        <div className="title-and-mark-container">
          <div className="teaser-image">
            <img src="lucite-mark-bubble.png" alt="Lucite Mark Bubble" />
          </div>
          <h1 className="teaser-text">
            <span>Demolish Distractions.</span>
            <span>Deliver Wins.</span>
          </h1>
        </div>
        <div className="coming-soon-text">Big things coming soon...</div>
        <div className="yc-container">
          <h6>Backed by</h6>
          <img src="yc-logo.png" alt="YC Logo" />
        </div>
      </div>
    </div>
  );
};

export default TeaserSection;
