import React from "react";
import "./TryLuciteButton.css";

function TryLuciteButton() {
  return (
    <button
      className="try-lucite-button"
      onClick={() => (window.location.href = "mailto:info@lucite.ai")}
    >
      <span>Try Lucite</span>
      <div className="liquid"></div>
    </button>
  );
}

export default TryLuciteButton;
